import React, { useState, useEffect } from 'react';
//import Confetti from 'react-confetti';
import './App.css';

const encodedTirage = "eyJDb2xlZW4iOiAiV2lsbGlhbSIsICJKb25haCI6ICJHdWlsbGF1bWUiLCAiSnVsZXMiOiAiTHVjYXMiLCAiTWFydGluIjogIkJyaWV1YyIsICJMdWNhcyI6ICJDb2xlZW4iLCAiVWx5c3NlIjogIlRoaWJhdWx0IiwgIkd1aWxsYXVtZSI6ICJFbGlhcyIsICJXaWxsaWFtIjogIk1hcnRpbiIsICJUaGliYXVsdCI6ICJVbHlzc2UiLCAiRWxpYXMiOiAiSnVsZXMiLCAiQnJpZXVjIjogIkpvbmFoIn0=";

function App() {
  const [nom, setNom] = useState('');
  const [resultat, setResultat] = useState('');


  const tirage = JSON.parse(atob(encodedTirage));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tirage[nom]) {
      setResultat(`Vous avez tiré : ${tirage[nom]}`);

    } else {
      setResultat("Nom non trouvé dans le tirage.");

    }
  };

  // Effet pour créer les flocons et feuilles
  useEffect(() => {
    const createSnowflakes = () => {
      const snowflake = document.createElement("div");
      snowflake.className = Math.random() < 0.05 ? "leaf" : "snowflake"; // 1 sur 20 est une feuille
      snowflake.innerText = snowflake.className === "leaf" ? "🍻" : "❄️";
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.animationDuration = `${Math.random() * 3 + 2}s`; // Durée aléatoire de chute
      document.body.appendChild(snowflake);

      // Supprime les flocons et feuilles après animation
      snowflake.addEventListener("animationend", () => {
        snowflake.remove();
      });
    };

    const snowflakeInterval = setInterval(createSnowflakes, 600);

    return () => clearInterval(snowflakeInterval);
  }, []);

  return (
    <div className="App">
      <h1>🎄 Secret Santa 🎄</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Entrez votre nom"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
        />
        <button type="submit">Voir le tirage</button>
      </form>

      {resultat && <p>{resultat}</p>}
    </div>
  );
}

export default App;
